.upload {
  padding: 0% 10% 10% 8%;
}
::-webkit-scrollbar {
    width: 8px;
    opacity: 100%;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(228, 231, 242, 1);
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    scroll-padding: 10px;
  }
  .metadataLabel {
    color: #3E456F;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    word-break: break-word;
  }
  .metadataSection {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(300px, 1.5fr);
}
@media screen and (max-width: 1200px) {
  .metadataSection {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 800px) {
  #rightContainer {
    padding: 0px;
  }
}
#descriptionInputContainer {
  margin-bottom: 20px;
}
#generateDescButton {
  margin: 0px 15px 10px 0px;
  padding: 13px 20px;
  border: none;
  background-color: #D5A9F4;
  color: white;
  font-weight: 600;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
#noMargin {
  margin: 0px;
}
#continuie {
  margin: 3px 15px 10px 0px;
  padding: 7px 15px;
  border: none;
  background-color: #D5A9F4;
  border-radius: 15px;
  color: white;
  white-space: nowrap;
  font-weight: 600;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: end;
}
/*#descWithButton {
  display: flex;
  flex-direction: column;
}
.metadataBigItem {
  width: 100%;
}*/
#saveButtons {
  margin-top: 40px;
  display: flex;
  justify-content: end;
}
.uploadDataset { 
  border: none;
  color: white;
  font-weight: 600;
  border-radius: 40px;
  font-size: 16px;
  text-wrap: nowrap;
}
.saveDraft {
  margin-right: 15px;
  padding: 20px;
  border: none;
  background-color: #7B93D2;
  color: white;
  font-weight: 600;
  border-radius: 40px;
  font-size: 16px;
}
.wrapper {
  display: flex;
  justify-content: center;
}

.cta {
  border: none;
  display: flex;
  padding: 10px 30px 10px 22px;
  align-items: center;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  color: white;
  background-color: #0071CE;
  border-radius: 50px;
}

.cta:focus {
   outline: none; 
}

.cta:hover {
    transition: 0.5s;
}

.cta span:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px;
}

.cta:hover  span:nth-child(2) {
    transition: 0.5s;
    margin-right: 30px;
}

  #arrowsAnimation {
    width: 10px;
    margin-left: 3px;
    position: relative;
    top: 8%;
    padding-right: 5px;
  }
  
/**************SVG****************/

path.one {
    transition: 0.4s;
    transform: translateX(-60%);
}

path.two {
    transition: 0.5s;
    transform: translateX(-30%);
}

.cta:hover path.three {
    animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
    0% {
        fill: white;
    }
    50% {
        fill: white;
    }
    100% {
        fill: white;
    }
}
.svgArrows {
  height: 25px;
  padding-right: 10px;
}
#uploadButtonText {
  color: white;
}
#addNewTagButton {
cursor: pointer;
border: solid 1px #3E456F;
border-radius: 100px;
color: #3E456F;
font-size: 20px;
font-weight: 400;
background-color: white;
line-height: 15px;
padding: 0px 10px;
}
#tagType {
  display: flex;
}
#rowContunuieLoading {
  display: flex;
  align-items: center;
}
#smallDescriptionContainer {
  margin-bottom: 20px;
}
#descriptionChoice, #descriptionChosen {
  margin: 3px 15px 5px 0px;
  padding: 5px 10px;
  border: none;
  font-weight: 600;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: end;
}
#descriptionChoice {
  border: none;
  color: #b575e3;
}
#descriptionChosen {
  background-color: white;
  color: #b575e3;
  border: 1px solid #b575e3
}

.progressBar {
  background-color: white;
  border-radius: 10px;
  height: 15px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  border: 1px solid #0071CE;
}
.progress {
  background-color: #0071CE;
  height: 100%;
  border-radius: 10px 0 0 10px;
  transition: width 0.6s ease;
  position: relative;
}
.generationText {
  color: #b575e3;
  font-weight: 500;
  margin: 0px 0px 5px 10px;
}
#visibilityChoice {
  display: flex;
  align-items: center;
  margin: 7px 0px 10px 0px;
}
.descriptionChoiceButtons {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}