#requestCard {
    margin: 1% 0px 0px 0px;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 95%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    z-index: 1;
}
#hoverRequest {
    width: 30.5%;
    margin: 1% 2.5% 0px 0px;
}
#arrowDown {
    width: 20px;
    margin: 0px 0px 3px 4px;
}
#showStagesButton {
    border: none;
    border-radius: 10px;
    color: #3E456F;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    margin: 5px 0px;
    display: flex;
    align-items: end;
}
#editIcon {
    width: 25px;
}
.rowEditDeleteRequestCard {
    display: flex;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 8px;
}
.deleteButtonOnRequest {
    border: none;
    display: flex;
    align-items: center;
}