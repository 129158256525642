.inputLabel {
    color: #3E456F;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    word-break: break-word;
}
.input {
    margin-bottom: 10px;
}
.textLimit {
    margin: 10px 0 0 10px;
    display: flex;
}