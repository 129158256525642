.back {
    display: flex;
    align-items: center;
    padding-top: 50px;
    margin-bottom: 10px;
    width: fit-content;
}
.back button {
    color: #3E456F;
    margin: 0.5%;
    font-size: 16px;
    font-weight: 500;
    border: none;
}