.dropdown-container {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  .share-button {
    border: 1px solid #0071CE;
    color: #0071CE;
    border-radius: 5px;
    padding: 2px 11px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    width: 230px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 5px;
  }
  
  .link-status {
    padding: 8px 10px;
    color: #0071CE;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  
  .link-status:hover {
    background-color: #e6f7ff;
  }
  
  .info-text {
    padding: 10px;
    color: #0071CE;
    font-size: 16px;
    line-height: 1.4;
  }
.shareButtonArrow {
    width: 22px;
    margin-left: 5px;
}
.blueDivider {
    height: 1px;
    background-color: #0071CE;
    margin-top: 5px;
}
.linkIcon {
    width: 18px;
    margin-left: 10px;
}