.file-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .file-name {
    font-weight: bold;
  }
  
  .file-size {
    color: #666;
  }
.file-list-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: fit-content;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 800px;
    max-height: 400px; /* Ограничение высоты для прокрутки */ 
    overflow-y: auto; /* Прокрутка, если контент превышает высоту */ 
    z-index: 10000;
  }