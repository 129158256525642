.privacyPolicy h2, .privacyPolicy h3 {
    text-align: center !important;
    margin-bottom: 8px;
}
  .privacyPolicy p {
    margin-left: 30px;
      text-align: justify !important;
  }
  .privacyPolicy ul {
      margin-left: 20px !important;
  }
  .privacyPolicy a {
    white-space: nowrap !important;
  }