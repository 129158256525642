#cookie-consent-layer {
    display: flex;
    justify-content: center;
}
.cookie-consent {
    position: fixed;
    bottom: 20px;
    width: 80%;
    align-self: center;
    background-color: #f8f9fa;
    border-top: 1px solid #ddd;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 
        0 8px 8px rgba(201, 201, 201, 0.5), /* Тень снизу */
        0 -4px 8px rgba(201, 201, 201, 0.5), /* Тень сверху */
        -4px 0 8px rgba(201, 201, 201, 0.5), /* Тень слева */
        4px 0 8px rgba(201, 201, 201, 0.5); /* Тень справа */
    z-index: 1005;
    border-radius: 10px;
  }
  
  .cookie-consent a {
    color: #007bff;
    text-decoration: none;
  }
  
  .cookie-consent a:hover {
    text-decoration: underline;
  }
  
  .cookie-consent .buttons {
    display: flex;
    gap: 15px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }
  
  .modal-cookie {
    background: #fff;
    padding: 25px 35px 25px 40px;
    width: 45%;
    border-radius: 8px;
    position: relative;
  }
  
  .modal-close-cookie {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }
  #acceptCookieButton, #rejectCookieButton {
    padding: 8px 20px;
    margin: 0;
  }
  .bigLineHeight {
    line-height: 30px;
  }