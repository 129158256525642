.modal-header-download {
  display: flex;
  justify-content: end;   
}

.modal-content-download {
  background-color: #fff;
  padding: 10px 40px 30px 40px;
  border-radius: 10px;
  max-width: 90%;
}
  
.modal-close-button {
  background: none;
  border: none;
  cursor: pointer;
}
#modalWindowDownload {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.downloadDatasetIcon {
    width: 45px;
    margin-right: 10px;
}
.typeDatasetLabel {
  margin-bottom: 15px;
}

#datasetBaseClean, #datasetAdvanced {
  font-size: 21px;
  font-weight: 600;
}
#datasetBaseClean {
  color: #0071CE;
}
#datasetAdvanced {
  color: #7B61FF;
}
.editProcces {
  margin-bottom: 5px;
}
#downloadDataset {
  font-size: 18px;
  padding: 10px 25px;
  align-content: center;
  margin-right: 20px;
}