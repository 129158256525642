.likeDislike {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 4px;
    gap: 15px;
    margin-top: 5px;
}
.like, .dislike, .clickedLike, .clickedDisLike, .not_allowed {
    color: #3E456F;
    transition: color 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border-radius: 30px;
    /* background-color: #d1eaff; */
    box-shadow: 0px 0px 6px #3E456F, 0px 0px 6px #3E456F;
}
.like:hover, .dislike:hover {
    filter: brightness(0.4);
}
.clickedLike {
    color: #0071CE;
    box-shadow: 0px 0px 10px #0071CE, 0px 0px 10px #0071CE;
}
.clickedLike:hover {
    box-shadow: 0px 0px 12px #0071CE, 0px 0px 12px #0071CE;
}
.clickedDisLike {
    color: #FF0000;
    box-shadow: 0px 0px 10px #FF0000, 0px 0px 10px #FF0000;
}
.clickedDisLike:hover {
    box-shadow: 0px 0px 12px #FF0000, 0px 0px 12px #FF0000;
}
.not_allowed {
    color: #A5A5A5;
    box-shadow: 0px 0px 8px #A5A5A5, 0px 0px 8px #A5A5A5;
}
.num_of_likes {
    font-weight: 500;
    color: currentColor;
}
.likeIcon, .dislikeIcon {
    color: currentColor;
}
#reportButton {
    color: #3E456F;
    height: fit-content;
    padding: 5px 10px;
    border: 1px solid #0071CE;
    color: #0071CE;
    font-weight: 500;
    margin-left: 20px;
    transition: box-shadow 0.2s ease;
}
#reportButton:hover {
    background-color: #EFF8FE;
}
#toolTipReactionWarning {
    background-color: white;
    border-radius: 10px;
    padding: 5px 15px;
    position: absolute;
    transform: translateX(70%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}