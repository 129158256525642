.datasetTopicSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 80px;
}
#search {
    width: 100%;
    padding: 3% 10% 10% 8%;
}
#searchSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
#main {
    font-size: 40px;
    font-weight: 700;
    width: fit-content;
    margin-right: 30px;
    width: 220px;
    margin-bottom: 7px;
}
#inputSearch {
    display: flex;
    align-items:first baseline;
    width: 100%;
}
body::before {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: inherit;
  }
#background {
    z-index: -1;
    width: 100%;
}
#searchButton {
    padding: 0px 25px;
    border: none;
    background-color: #0071CE;
    color: white;
    font-weight: 600;
    font-size: 16px;
    height: 40px;
    margin-left: 10px;
}
#searchIcon {
    border: none;
    width: 50px;
    display: none;
}
#search input[type="text"], #datasets input[type="text"] {
    padding: 20px;
    border-width: 1px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: none;
    height: 35px;
    font-size: 16px;
    width: 100%;
  }
#correctInput {
    border: solid rgba(228, 231, 242, 1);
    color: #3E456F;
    width: 100%;
}
#search input[type="text"]:focus, #datasets input[type="text"]:focus {
    border: solid rgba(0, 113, 206, 1);
    outline: none;
    border-width: 1px;
  }
#filterIcon {
    margin: 0px 5px;
}
#wrongInput {
    color: rgb(225, 0, 0);
    border: 1px solid rgb(225, 0, 0);
}
#popularTopicsLabel {
    font-size: 50px;
    font-weight: 700;
    width: fit-content;
}
#popularTopicsSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.topicsList {
    margin-left: 220px;
    display: flex;
    width: 90%;
    align-items: center;
    flex-wrap: wrap;
}
#topic {
    margin: 0px 7px 7px 7px;
    padding: 5px 18px;
    border-radius: 50px !important;
}
#searchRow {
    display: flex;
    align-items: center;
}
#uploadIcon {
    display: none;
}
@media screen and (max-width: 1300px) {
    .topicsList {
        margin-left: 0px;
    }
}
@media screen and (max-width: 1000px) {
    #searchSection {
        flex-direction: column;
    }
    #main {
        margin: 22px 0px;
    }
    #searchRow {
        margin-bottom: 15px;
    }
    #uploadIcon {
        display: block;
    }
    #uploadDatasetButton {
        display: none;
    }
}
#advancedSearch {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-top: 8px;
    border: none;
    img {
        width: 22px;
    }
    p {
        color: #0071CE;
        border-bottom: 1px solid #0071CE;
    }
}
#tagAdvancedSearch {
    padding: 0px 15px;
    line-height: 25px;
    color: #0071CE;
    border-radius: 20px;
    cursor: pointer;
    width: fit-content;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin: 3px 5px;
    position: relative;
    white-space: nowrap;
}