.reportWindow {
    background-color: #fff;
    padding: 25px 40px; /* Отступы внутри модального окна */
    border-radius: 15px;
    width: fit-content;
    min-width: 40%;
    max-width: 90%;
    max-height: 80vh; /* Используйте vh для обеспечения корректной высоты окна */
    overflow: hidden; /* Убираем скролл с самого модального окна */
    display: flex;
    flex-direction: column; /* Позволяет размещать элементы вертикально */
}

/* CSS для контейнера скролла */
.scroll {
    flex: 1; /* Позволяет .scroll занимать все доступное пространство */
    overflow-y: auto; /* Добавляем вертикальный скролл при необходимости */
    margin-right: 5px; /* Отступ справа для скролл-бара */
    margin-top: 15px; /* Отступ сверху для разделения с заголовком */
}
.reportHeader {
    display: flex;
    justify-content: space-between;
    align-items:first baseline;
    margin-bottom: 15px;
}
#warningButtons {
    margin-top: 20px;
    display: flex;
    justify-content: end;
}
.report-close-button {
    border: none;
    font-size: 25px;
    color: #3E456F;
}
.reportFile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F5F5;
    padding: 5px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}
#reportFileName {
    font-size: 18px;
    font-weight: 600;
    margin-right: 20px;
}
.reportWarning {
    color: #D80000;
}
#reportLabel {
    margin-right: 20px;
}
#backToFiles {
    border: none;
    color: #3E456F;
    display: flex;
    align-items: center;
}
#backToFilesButtonLabel {
    font-size: 16px;
    margin-left: 5px;
}
.line {
    display: flex;
    background-color: #ffc2c2;
    padding: 2px 10px;
    font-weight: 500;
    margin-right: 10px;
}
#showFile {
    font-size: 16px;
}