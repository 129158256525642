#datasets {
    padding: 0% 10% 160px 8%;
}
#sort {
    width: 300px;
    margin-right: 5px;
    margin-left: 10px;
}
@media screen and (max-width: 900px) {
    #sort {
        width: 150px;
    }
}
.searchFiltersSort {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 10px;
}
#inputSearchDatasets {
    width: 100%;
    display: flex;
    align-items: center;
}
#cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
#cardsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}