#datasetListItem {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(103, 103, 103, 0.2);
  padding: 6px 20px;
  margin-bottom: 10px;
  width: 100%;
}
#briefDatasetListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#uploadedDatasetTitleList, #datasetTitleList {
  font-size: 18px;
  font-weight: 500;
  transition: color ease 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 30px;
}
@media screen and (max-width: 680px) {
    #uploadedDatasetTitleList {
        font-size: 16px;
    }
}
#uploadedDatasetTitleList:hover {
    color: #00559b;
}
#status {
    display: flex;
    align-items: center;
    width: fit-content;
    white-space: nowrap;
}
.statusIcon {
    width: 25px;
}
.statusIconSmaller {
    width: 20px;
}
.stageStatusIcon {
    width: 20px;
    margin-right: 10px;
}
.done, .not_started, .in_progress, .failed {
    color: #00BA55;
    font-size: 17px;
    line-height: 20px;
    margin-right: 5px;
}
.not_started {
    color: #757575;
}
.in_progress {
    color: #3E456F;
}
.failed {
    color: #CC0000;
}
.doneNet, .not_startedNet, .in_progressNet, .failedNet {
    height: 15px;
    width: 2px;
    margin: 0;
    margin-left: 9px;
    background-color: #00BA55;
}
.not_startedNet {
    background-color: transparent;
}
.in_progressNet {
    background-color: #3E456F;
}
.failedNet {
    background-color: #CC0000;
}
.stageDate {
    font-size: 17px;
    color: #A5A5A5;
    line-height: 20px;
}
.stages {
    margin: 0px 0px 15px 0px;
}
.rightSectionList {
    display: flex;
    align-items: center;
}
#trashIconList {
    width: 20px;
    margin: 0px 10px;
}
#requestIcon {
    width: 18px;
    margin-left: 10px;
}
#editIconRequest {
    width: 26px;
    margin-right: 5px;
}
#stageStatus {
    display: flex;
    align-items: center;
    margin: 0;
    width: 700px;
}
.showReportButton {
    font-size: 17px;
    text-decoration: underline;
    border: none;
    color: #3E456F;
    margin-left: 10px;
    line-height: 5px;
}
.rowTitleAndStatus {
    display: flex;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .rowTitleAndStatus {
        justify-content: space-between;
    }
}
#seeDatasetButton {
    margin-left: 8px;
    margin-right: 15px;
    padding: 2px 15px;
}
#requestButton {
    margin-left: 8px;
    margin-right: 5px;
    padding: 3px 15px;
}
#visibilityLabel {
    padding: 12px 15px;
    margin: 0;
    margin-right: 20px;
    border-radius: 10px !important;
    color: #0071CE !important;
    pointer-events: none;
}