.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-content-filters {
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
  }
  .modal-content {
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 10px;
    width: 50%;
    max-width: 90%;
  }
  .modal-header-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5px;
    margin-top: 15px;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .modal-close-button {
    background: none;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
#applyFilters {
  padding: 5px 15px;
  margin-left: 7px;
  border: none;
  background-color: #3E456F;
  color: white;
  font-weight: 600;
  border-radius: 40px;
  font-size: 16px;
  height: 40px;
}
#applyFiltersLayout {
  display: flex;
  justify-content: end;
}
.sliderRoot {
  margin: 25px 0px !important;
}
.sliderLabel {
  background-color: transparent !important;
  color: #0071CE !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  margin-bottom: -5px !important;
}
.sliderTrack {
  height: 5px !important;
}
.sliderMark {
  display: none;
}
.sliderMarkLabel {
  margin-top: 10px !important;
}
.mantine-Popover-dropdown {
  svg {
    width: 20px !important;
    margin: -3px 10px;
  }
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.multiselectInput {
    border: 1px solid rgba(228, 231, 242, 1) !important;
    border-radius: 10px;
    font-size: 16px !important;
    padding: 21px !important;
    width: 230px !important;
    border-radius: 10px !important;
}
.mantine-DateInput-day[disabled]{
  color: rgb(195, 195, 195) !important;
  pointer-events: none !important;
}
#addTagButton {
  height: 40px;
  margin-left: 10px;  
}
.mantine-Tooltip-tooltip {
  color: #3E456F !important;
  border-radius: 10px !important;
  padding: 7px 20px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}