#unsuccessUpload {
    padding: 0% 10% 10% 8%;
    }
#mainSectionUnsuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    gap: 20px
}
#repeatUpload {
    margin: 15px auto 10px auto;
    padding: 15px 50px 15px 50px;
    border: none;
    background-color: #7B93D2;
    color: white;
    font-weight: 600;
    border-radius: 40px;
    font-size: 17px;
}
#onMain {
  margin-right: 15px;
  padding: 15px 40px 15px 40px;
  border: none;
  background-color: #D19DEA;
  color: white;
  font-weight: 600;
  border-radius: 40px;
  font-size: 17px;
}