.headerContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    height: 64px;
    width: 100%;
    background-color: #ffffff;
    padding: 0;
    z-index: 12;
    margin: 0;
    border-bottom: 1px solid #E4E7F2;
  }
  
  .productLogo {
    margin-left: 28px;
    width: 140px;
    cursor: pointer;
    height: 36px;
    fill: none;
    flex-shrink: 0;
    z-index: 12;
  }
  
  .productButtons {
    display: flex;
    flex-direction: row;
    margin: auto;
    align-items: flex-start;
    gap: 32px;
    z-index: 12;
    z-index: 12;
  }
  
  .productButton {
    color: var(--Grey-70, #3b4168);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    z-index: 12;
  
    line-height: 20px;
  }
  
  .productButton:active {
    background-color: rgba(175, 194, 241, 0.28);
  }
  
  .productButton:hover {
    color: var(--Blue-60-main, #0071ce);
  }
  
  .userDropdown {
    color: #3b4168;
    font-family: IBM Plex sans, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  
    min-width: 200px;
    gap: 1rem;
    height: 44px;
  
    margin-right: 28px;
    font-size: 16px;
    font-weight: 500;
  
    display: flex;
    align-items: center;
    padding: 0 1rem;
    box-shadow: 0 4px 15px rgba(33, 36, 55, 0.12);
    justify-content: space-between;
    border-radius: 6px;
  
    user-select: none;
    cursor: pointer;
  }
#warningNotification {
  background-color: #D3EBFF;
  color: #0071CE;
  padding: 8px 20px;
  text-align: center;
}
.menuItem {
  background-color: white !important;
  color: #3E456F !important;
  border-radius: 0px !important;
  margin: 0;
  padding: 10px !important;
}
.menuItem:hover {
  background-color: #EFF8FE !important;
  filter: brightness(1);
  color: #0071CE !important;
}
.menuItemLabel {
  font-size: 15px;
  color: inherit;
}
.menuDropdown {
  border-radius: 10px !important;
  padding: 0;
}
.alertBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
}

.alertBox {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.alertInput {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.alertButtons {
  display: flex;
  gap: 10px;
}

.alertButton {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.alertButton:hover {
  background-color: #0056b3;
}
