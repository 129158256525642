.uploadFile, .uploadImage {
    padding: 15px;
    background-color: #EFF8FE;
    height: 200px;
    border-radius: 20px;
    border: 2px dashed #0071CE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.uploadFile {
    width: 90%;
}
.uploadImage {
    width: 75%;
}
.filesSection {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  margin-top: 10px;
}
#filesPart {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.seeFiles, .seeImage {
    color: #0071CE;
}
.seeImage:hover, .seeFiles:hover {
    font-weight: 500;
}
.limitFile {
    font-size: 17px;
    font-weight: 500;
    color: #3E456F;
    margin-left: 20px;
}
.fieldInfo {
    line-height: 28px;
}
.dropFile {
    font-size: 18px;
    color: #909EBB;
    margin: 10px 0px;
    text-align: center;
}
.seeFiles {
    width: fit-content;
    font-size: 18px;
    font-weight: 400;
    border: none;
}
@media screen and (max-width: 800px) {
    .seeImage {
        font-size: 14px;
    }
}
.dropImage {
    height: fit-content;
    line-height: 20px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}
.limitImage {
    font-size: 17px;
    font-weight: 500;
}
.uploadFilesContainer {
    display: flex;
}
.uploadedFilesSection {
    padding: 10px 25px 15px 25px;
    border-radius: 20px;
    border: 1px solid #3E456F;
    color: #3E456F;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    height: 200px;
}
.coverImageText {
    color: #3E456F;
    line-height: 20px;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}
#filesSectionTitle {
    color: #3E456F;
    width: fit-content;
    height: fit-content;
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
}
.uploadPage {
    margin-bottom: 30px;
}
.uploadedImagePreview {
    margin-top: 20px;
}
#coverPreviewImage {
    background-color: #E1C5F5;
    border-radius: 20px;
    box-sizing: border-box;
    height: 100%;
    width: auto;
    aspect-ratio: 5 / 3;
    object-fit: cover;
    margin-bottom: 20px;
}
#datasetImageUpload {
    height: 190px;
    border-radius: 20px;
    position: relative;
    display: inline-block;
}
#uploadChoice, #uploadChosen {
    margin: 3px 15px 0px 10px;
    line-height: 18px;
    border: none;
    font-weight: 600;
    border-radius: 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  #uploadChoice {
    border: none;
    color: #0071CE;
    padding: 5px 2px 5px 10px;
  }
  #uploadChosen {
    background-color: white;
    color: #0071CE;
    border: 1px solid #0071CE;
    padding: 5px 10px;
  }
  #deleteImageIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  #folder {
    color: #3E456F;
    font-size: 16px;
    font-weight: 500;
    padding-left: 4px;
  }
  #labelUploadedFiles {
    color: #3E456F;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  #requiredFieldsLabel {
    color: #0071CE;
  }  
@media screen and (max-width: 1200px) {
    .filesSection {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .uploadImage {
        width: 400px;
        margin-bottom: 10px;
    }
    .uploadFile {
        margin-bottom: 10px;
        width: 90%;
    }
}

@media screen and (max-width: 700px) {
    .uploadFilesContainer {
        display: flex;
        flex-direction: column;
    }
    .uploadFile, .uploadImage {
        width: 100%;
        margin-bottom: 40px;
    }
    #filesPart {
        display: flex;
        flex-direction: column;
    }
}