#datasetCard {
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 97%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    cursor: pointer;
}
#datasetCard:hover {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
#hover {
    width: 31%;
    margin: 1%;
}
#datasetCardDescription {
    border-radius: 0px 0px 20px 20px;
    color: #3b4168;
    position: absolute;
    padding: 0px 30px 20px 30px;
    width: 25.44%;
    z-index: 1000;
    background-color: white;
    transition: all 0.7s ease;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
#datasetLoading {
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 97%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    cursor: pointer;
}
#datasetImage {
    width: 90px;
    height: 90px;
    aspect-ratio: 4 / 4;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 20px;    
}
#cardInfo {
    margin-top: 10px;
    width: 100%;
    position: relative;
}
.fieldInfo {
    margin-right: 7px;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#renewData {
    color: #6b7092;
    margin: 0;
}
#authors {
    width: 100%;
    margin: 0;
}
#downloadsNumber {
    margin: 0;
    height: fit-content;
}
#datasetTitleOnCard {
    font-size: 20px;
    position: relative;    
    margin: 0;
    margin-right: 10px;
    height: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media screen and (max-width: 1500px){
    #datasetTitleOnCard {
        font-size: 18px;
    }
    #datasetImage {
        width: 70px;
        height: 70px;
    }
}
@media screen and (max-width: 1300px) {
    #hover {
        width: 48%;
    }
    #datasetCardDescription {
        width: 39.35%;
    }
    #datasetTitleOnCard {
        font-size: 17px;
    }
}
@media screen and (max-width: 850px) {
    #hover {
        width: 90%;
    }
    #datasetCardDescription {
        width: 73.8%;
    }
}
.imageLoadingAnimation {
	background: linear-gradient(90deg, #f4f8ffc8, #e4e4e4, #f8faffc8, #e4e4e4);
	background-size: 400% 400%;
	animation: gradient 2s ease infinite;
	transform: translate3d(0, 0, 0);
}
 
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.textLoadingAnimation {
	background: linear-gradient(90deg, #f4f8ffc8, #e4e4e4, #f8faffc8, #e4e4e4);
	background-size: 400% 400%;
	animation: gradient 2s ease infinite;
	transform: translate3d(0, 0, 0);
    height: 5px;
}
 
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}