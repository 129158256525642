#tagFilter {
    padding: 5px 15px;
    color: white;
    background-color: #0071CE;
    border-radius: 20px;
    cursor: pointer;    
    width: fit-content;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-bottom: 5px;
}
#tag,#tagFilterSuggestion {
    padding: 1.5px 15px;
    color: #0071CE;
    border-radius: 20px;
    cursor: pointer;
    width: fit-content;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 3px 5px;
    position: relative;
    white-space: nowrap;
}
#tagFilterSuggestion {
    border: 1px solid #0071CE;
    line-height: 32px;
}
.clearTagInput {
    margin-right: 5px;
    padding: 2px 15px;
}
#inputTagsFilter {
    display: flex;
    margin-bottom: 8px;
    width: 100%;
}
#tagTypeLabel {
    min-width: 200px !important;
    padding-top: 7px;
    margin: 0;
    font-weight: 500;
    white-space: nowrap;
  }
.input-container {
    display: flex;
    align-items: baseline;
    margin-right: 10px;
}
.selectTags {
    padding: 4px 10px;
    border: 1px solid #3E456F;
    border-radius: 50px;
    cursor: pointer;    
    width: fit-content;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    background-color: #3E456F;
    color: aliceblue;
  }
.selectTags:focus {
    border: solid rgba(0, 113, 206, 1);
    outline: none;
    border-width: 1px;
 }
 .selectTags::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
#filterTagLabel {
    background-color: white;
    color: #3E456F;
    font-size: 16px;
    font-weight: 600px;
}
.rowTags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: fit-content;
}
.tagsDisplay {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: fit-content;
}
#deleteTagButton {
    border: none;
    font-size: 20px;
    margin-bottom: 2px;
    margin-left: 6px;
    padding: 1px;
    color: #0071CE;
}
@media screen and (max-width: 1200px) {
    #inputTagsFilter {
        flex-direction: column;
    }
}
@media screen and (max-width: 1000px) {
    #inputTagsFilter {
        flex-direction: column;
    }
    #inputTagsFilterWarning {
        flex-direction: column;
    }
    #tagTypeLabel {
        padding-top: 7px;
        margin-bottom: 5px;
    }
    .tagsDisplay {
        margin-top: 5px;
    }
}