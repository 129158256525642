.modal-content-copy-code {
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 10px;
  }
#blueCode, #purpleCode {
    padding: 10px;
    padding-right: 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
}
#purpleCode {
    background-color: #E9DFFF;
    color: #7B61FF;
  }
#purpleCode::-webkit-scrollbar-thumb {
    background-color: #9e8aff;
}
#blueCode {
    background-color: #D3EBFF;
    color: #0071CE;
}
#blueCode::-webkit-scrollbar-thumb {
    background-color: #4889bf;
}
.codeLabel {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.tooltip {
    position: absolute;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    background-color: white;
    color: #fff;
    padding: 8px 20px 8px 2px;
    border-radius: 10px;
    top: 120%;
    left: 90%; /* Center horizontally */
    transform: translateX(-50%);
    z-index: 1001; /* Ensure it appears above other elements */
    white-space: nowrap; /* Prevent text from wrapping */
}
#codeContainer {
    display: flex;
    align-items: center;
}
#blueCodeContainer {
    margin-right: 50px;
}
@media screen and (max-width: 1700px) {
    #codeContainer {
        flex-direction: column;
        justify-content: center;     
    }
    #blueCodeContainer {
        margin-right: 0px;
        margin-bottom: 50px;
    }
    #blueCode, #purpleCode {
        height: 200px;
    }
}
@media screen and (max-width: 900px) {
    .modal-content-copy-code {
        scale: 0.8;    
    }
}
@media screen and (max-width: 700px) {
    .modal-content-copy-code {
        scale: 0.7;    
    }
}