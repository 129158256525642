.uploadedFile, .uploadedFile a {
    background-color: #F5F5F5;
    color: #3E456F;
    padding: 1.5%;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.uploadedFile {
    margin-bottom: 7px;
}
.uploadedFile:hover {
    background-color: #e9e9e9;
}
#uploadedFileName {
    padding-left: 4%;
    color: #3b4168;
}