#datasetPage {
    padding: 0% 10% 90px 8%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}
#metainfo {
    background-color: #D3EBFF;
    width: fit-content;
    max-width: 30%;
    padding: 25px 35px;
    border-radius: 15px;
    height: fit-content;
}
#meta {
    margin-bottom: 10px;
}
.metaWhite {
    color: #0071CE;
}
.infoContainer {
    margin-bottom: 10px;
}
#datasetInfoHeader {
    display: flex;   
    margin-top: 40px;
}
#section {
    margin-right: 50px;
}
#datasetInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding-top: 50px;
    border-top: solid 1px #7a80a4;
}
#datasetCoverImage {
    border-radius: 30px;
    width: 25%;
    height: auto;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    margin-bottom: 25px;
}
#mainInfo {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
#downloadButton, #copyCodeButton {
    margin: 0;
    display: flex;
    justify-content: center;
    padding: 8px 20px 8px 24px;
}
#downloadButton {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: fit-content;
}
#copyCodeButton {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    margin-right: 20px;
    width: fit-content;
}
#downloadSection {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
#downloadButtons {
    display: flex;
    align-items: center;
}
#copyCodeLabel, #downloadLabel {
    font-size: 17px;
    margin-right: 8px;
}
#downloadLabel {
    color: white;
}
#copyCodeLabel {
    color: #0071CE;
}
#downloadIcon{
    width: 17px;
}
#copyCodeIcon {
    width: 20px;
}
#versionLabel {
    padding-right: 10px;
}
.downloadIcon {
    margin-right: 10px;
}
.datasetTag {
    border: solid 1px #3E456F;
    border-radius: 15px;
    color: #3E456F;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: white;
}
#tags {
    margin: 5px 10px 15px 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.author {
    color: #3E456F;
    font-size: 18px;
    font-weight: 500;
    padding-left: 4px;
    line-height: 20px;
    margin-right: 2%;
    margin: 0;
}
#descriptionLabel {
    margin-bottom: 10px;
}
#description {
    width: 100%;
    margin-bottom: 30px;
}
#rating {
    font-weight: 500;
    margin-left: 3px;
}
#ratingLabel {
    display: flex;
    align-items: center;
}
#numOfDownloads {
    font-weight: 500;
    color: #5C659D;
}
#visibilityLabel {
    color: #3E456F;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    width: fit-content;
    height: fit-content;
    line-height: 5px;
}
#editDatasetButton {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 12px 23px;
    border: solid 2px #3E456F;
    color: #3E456F;
    margin-right: 1%;
    font-weight: 700;
    border-radius: 40px;
}
#editDatasetButton:hover {
    background-color: #3E456F;
    color: white;
}
#deleteDatasetButton {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 12px 23px;
    border: solid 2px #b45459;
    color: #b45459;
    font-weight: 700;
    border-radius: 40px;
}
#deleteDatasetButton:hover {
    background-color: #b45459;
    color: white;
}
.folderMargin {
    margin-top: 8px;
}
#folderIcon {
    width: 20px;
    margin-right: 10px;
}
#folderName {
    color: #3E456F;
    font-size: 18px;
    font-weight: 500;
    padding-left: 4px;
    line-height: 20px;
    margin-right: 2%;
    margin-bottom: 5px;
}
.capitalize {
    text-transform: capitalize;
}
@media screen and (max-width: 1250px) {
    #datasetCoverImage {
        width: 300px;
        height: 200px;
    }
    #mainInfo {
        margin-left: 30px;
    }
    #metainfo {
        max-width: 100%;
    }
}
@media screen and (max-width: 1070px) {
    #datasetInfo {
        flex-direction: column-reverse;
    }
    #metainfo {
        width: 100%;
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 980px) {
    #downloadSection {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }
}
@media screen and (max-width: 880px) {
    #downloadSection {
        flex-direction: row;
        gap: 0px;
        align-items: center;
    }
    #datasetCoverImage {
        display: none;
    }
    #mainInfo {
        margin-left: 0px;
    }
}
